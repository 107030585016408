import { template as template_cefa2a78fd654dd7b542705f7325106b } from "@ember/template-compiler";
const FKLabel = template_cefa2a78fd654dd7b542705f7325106b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
