import { template as template_08ff6ff57fa54f4788e31af60c5d25db } from "@ember/template-compiler";
const FKControlMenuContainer = template_08ff6ff57fa54f4788e31af60c5d25db(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
